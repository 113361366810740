import { Box, CardMedia, Tooltip, Typography } from "@mui/material";
import PATHS from "../../../constants/Paths";
import { TeaserCard } from "@rockwell-automation-inc/ra-meatball";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DisassembledProduct from "../../../interfaces/DisassembledProduct";
import { selectUser, usePSAppSelector } from "platform-services";
import { featureFlagItpacs769 } from "./featureFlag";

interface ProductCardProps {
  product: DisassembledProduct;
}
const DisassembledProductCard = (props: ProductCardProps) => {
  const { product } = props;
  const user = usePSAppSelector(selectUser);

  return (
    <TeaserCard
      raVariant="horizontal"
      hover={false}
      sx={{
        fontFamily: "inherit",
        borderRadius: "4px",
        justifyContent: "start",
        width: "350px",
        height: "230px",
        gap: "8px",
        border: "0px 0px 4px 0px",
        padding: "20px",
      }}>
      <Box sx={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
        {product.photo ? (
          <>
            <CardMedia
              sx={{
                width: "60px",
                height: "60px",
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              component="img"
              image={`${PATHS.IMG_BASE}${product.photo}`}
              alt="component_img"
            />
          </>
        ) : (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 48 48" fill="none">
              <path
                d="M42 31.9998V15.9998C41.9993 15.2984 41.8141 14.6094 41.4631 14.0021C41.112 13.3948 40.6075 12.8905 40 12.5398L26 4.5398C25.3919 4.18873 24.7021 4.00391 24 4.00391C23.2979 4.00391 22.6081 4.18873 22 4.5398L8 12.5398C7.39253 12.8905 6.88796 13.3948 6.53692 14.0021C6.18589 14.6094 6.00072 15.2984 6 15.9998V31.9998C6.00072 32.7013 6.18589 33.3902 6.53692 33.9975C6.88796 34.6048 7.39253 35.1091 8 35.4598L22 43.4598C22.6081 43.8109 23.2979 43.9957 24 43.9957C24.7021 43.9957 25.3919 43.8109 26 43.4598L40 35.4598C40.6075 35.1091 41.112 34.6048 41.4631 33.9975C41.8141 33.3902 41.9993 32.7013 42 31.9998Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M6.54004 13.9219L24 24.0219L41.46 13.9219" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M24 44.16V24" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </>
        )}
      </Box>
      <Box sx={{ margin: "auto" }}>
        <Typography
          sx={{
            fontFamily: "Barlow !important",
            fontWeight: 500,
            marginBottom: "10px",
          }}>
          {product.catalogNumber}
        </Typography>
        {product.description !== null && (
          <Typography sx={{ fontFamily: "Barlow !important", marginBottom: "10px" }}>
            {product.description.length > 75 ? product.description.slice(0, 75) + "..." : product.description}
          </Typography>
        )}
        {user ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: "10px",
              }}>
              <Box>
                <Typography>
                  {product.displayPrice}
                  {featureFlagItpacs769 && product.componentQty && product.componentQty > 1 ? "/each" : ""}
                </Typography>
                {featureFlagItpacs769 && product.componentQty && <Typography>Quantity: {product.componentQty}</Typography>}
              </Box>
              {!featureFlagItpacs769 && (
                <Tooltip
                  sx={{ marginLeft: "10px" }}
                  placement="bottom"
                  arrow
                  title="Disassembled parts are usually more expensive than a factory assembled product. Shipping costs included in the price.">
                  <InfoOutlinedIcon></InfoOutlinedIcon>
                </Tooltip>
              )}
            </Box>
            <Typography sx={{ fontFamily: "Barlow !important", fontWeight: 500 }}>{product.stockStatusDisplay}</Typography>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}>
              <Typography sx={{ fontFamily: "Barlow !important", color: "#003E7E" }}>Log in to see the price</Typography>
            </Box>
            <Typography sx={{ fontFamily: "Barlow !important", color: "#003E7E" }}>Log in to see lead time</Typography>
          </>
        )}
      </Box>
    </TeaserCard>
  );
};

export default DisassembledProductCard;
