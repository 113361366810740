import { Box, Button, Container, Grid, Link as MuiLink, Typography, Tooltip } from "@mui/material";
import Masthead from "../../components/masthead/Masthead";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import {
  SettingsIcon,
  TeaserCard,
  TeaserCardContent,
  TeaserCardDescription,
  TeaserCardMedia,
  TeaserCardSubtitle,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import systemDesigners from "../../configFiles/system-designer-cards.json";
import { Link } from "react-router-dom";
import { GetURLQueryVariable } from "../../util/GetURLQueryVariable";
import config from "../../config";
import { useAppSelector } from "../../redux/hooks";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import { FeatureFlag, selectUser, usePSAppSelector } from "platform-services";
import { useEffect, useState } from "react";
import FEATURES from "../../constants/FeatureFlags";

export interface IDesignerCard {
  type: string;
  imageUrl: string;
  familyHeader: string;
  familySubheader: string;
  description: string;
  actionText: string;
  baseUrl: string;
  path: string;
  isFeatureFlagged: boolean;
  featureFlagName: string;
}

const SystemDesigners = () => {
  const [designerCards, setDesignerCards] = useState<IDesignerCard[]>([]);
  interface TagProps {
    text: string;
  }
  useEffect(() => {
    const designerCardsData: IDesignerCard[] = systemDesigners.filter(designer => {
      if (designer.isFeatureFlagged && designer.featureFlagName) {
        return window.featureFlags[designer.featureFlagName as keyof typeof window.featureFlags] === "true";
      }
      return true;
    });

    setDesignerCards(designerCardsData);
  }, [window.featureFlags]);

  const user = usePSAppSelector(selectUser);
  const project = useAppSelector(selectSelProject);

  let configureNote = "";

  if (!user) {
    configureNote = "Log In to Configure this component ";
  } else if (!project) {
    configureNote = "Create/Select a Project to Configure this component";
  }

  const Tag: React.FC<TagProps> = ({ text }) => {
    const borderColor = "#2A79C4";
    const backgroundColor = "#C0D7EE";
    return (
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "25px",
          backgroundColor: backgroundColor,

          px: 2,
          border: `1px solid ${borderColor}`,
          borderRadius: "14px",
        }}>
        <Typography fontWeight="bold" sx={{ color: borderColor, textTransform: "uppercase" }}>
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Masthead />

      <Container sx={{ my: 0, py: 3 }}>
        <SearchBreadcrumbs loading={false} systemDesigner />
      </Container>

      <Container sx={{ mb: 0, mt: 4, pt: 0, pb: 4, pl: 0, pr: 0 }} style={{ backgroundColor: "white" }}>
        <Grid container spacing={2}>
          {designerCards.map(designer => {
            return (
              <Grid item>
                <TeaserCard
                  sx={{
                    ml: "auto",
                    mr: "auto",
                    width: "350px",
                    height: 575,
                    py: 2,
                    px: 1,
                    position: "relative",
                  }}
                  onClick={() => {}}
                  raVariant="vertical"
                  hover={false}>
                  <Tag text={designer.type} />
                  <TeaserCardMedia image={designer.imageUrl} sx={{ mt: 4, mx: 4 }} style={{ backgroundSize: "contain" }} />
                  <TeaserCardContent style={{ paddingBottom: "0px" }}>
                    <TeaserCardSubtitle sx={{ textAlign: "center", minHeight: "19px" }}>{designer.familySubheader}</TeaserCardSubtitle>
                    <TeaserCardTitle
                      variant="h6"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{
                        fontSize: "1.2rem",
                        mb: 4,
                      }}>
                      {designer.familyHeader}
                    </TeaserCardTitle>
                    <TeaserCardDescription sx={{ minHeight: "125px" }}>{designer.description}</TeaserCardDescription>
                    <Tooltip disableFocusListener title={configureNote}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "30px",
                        }}>
                        <Button
                          variant="outlined"
                          startIcon={<SettingsIcon />}
                          component={Link}
                          to={`${config[designer.baseUrl as keyof typeof config]}${designer.path}?projectGuid=${GetURLQueryVariable(
                            "projectGuid"
                          )}&projectId=${GetURLQueryVariable("projectId")}`}>
                          {designer.actionText}
                        </Button>
                      </Box>
                    </Tooltip>
                  </TeaserCardContent>
                </TeaserCard>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export default SystemDesigners;
