import { useState } from "react";
import { Avatar, Box, Button, CardMedia, Tooltip, Typography, useTheme } from "@mui/material";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import PATHS from "../../constants/Paths";
import defaultImage from "../../assets/Image_Unavailable.svg";
import { InformationCircleIcon } from "@rockwell-automation-inc/ra-meatball";
import { useConfiguratorData } from "../../contexts/ConfiguratorDataContext";

interface ProductRealTimeDataProps {
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  resetConfiguration: Function;
}

const ProductRealTimeData = (props: ProductRealTimeDataProps) => {
  const { configFromTemplateDataFromParent, resetConfiguration } = props;
  const [imageSrc, setImageSrc] = useState(`${PATHS.IMG_BASE}${configFromTemplateDataFromParent.properties.Photo}`);
  const theme = useTheme();
  const { isViewSummaryVisible, setIsViewSummaryVisible } = useConfiguratorData();

  const handleResetConfiguration = () => {
    resetConfiguration();
  };

  const handleImageError = () => {
    setImageSrc(defaultImage);
  };

  const toggleConfigurationSummary = () => {
    setIsViewSummaryVisible(!isViewSummaryVisible);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: "solid 1px #A6A9AB",
          width: "100%",
          paddingBottom: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Box sx={{ width: "49%", height: "100%", alignContent: "start" }}>
          <Box sx={{ display: "flex", height: "66%", width: "100%", alignItems: "center" }}>
            <Box sx={{ height: "105px", width: "104px" }}>
              {configFromTemplateDataFromParent.properties.Photo ? (
                <CardMedia
                  sx={{ width: "100%", height: "100%", backgroundSize: "contain", objectFit: "contain" }}
                  component="img"
                  image={imageSrc}
                  onError={handleImageError}
                  alt="component_img"
                />
              ) : (
                <Avatar variant="square" src={defaultImage} sx={{ height: "100%", width: "100%", bgcolor: "transparent" }} />
              )}
            </Box>
            <Typography
              sx={{
                fontFamily: "Barlow !important",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "28px",
                paddingLeft: "10px",
              }}>
              {configFromTemplateDataFromParent.title}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", paddingBottom: "5px", visibility: "hidden" }}>
            <Typography>STOCK STATUS</Typography>
            <Typography>LEAD TIME</Typography>
          </Box>
          <Box sx={{ visibility: "hidden" }}>
            <Typography>LIFECYCLE STATUS</Typography>
          </Box>
        </Box>
        <Box sx={{ border: "solid 1px #A6A9AB", height: "66%" }}></Box>
        <Box sx={{ width: "49%", height: "100%", alignContent: "end" }}>
          <Box sx={{ display: "flex", justifyContent: "end", padding: "6px 8px", visibility: "hidden" }}>
            <Typography
              sx={{
                fontFamily: "Barlow !important",
                fontWeight: "500",
                fontSize: "28px",
                lineHeight: "32px",
                paddingRight: "10px",
              }}>
              PRICE
            </Typography>
            <Tooltip
              placement="bottom"
              title={
                <Box
                  sx={{
                    color: theme.palette.mediumGrey.main,
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    fontSize: "12px",
                    lineHeight: "18px",
                    p: 1,
                  }}>
                  <span></span>
                </Box>
              }>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InformationCircleIcon sx={{ width: "18px", height: "18px" }} />
              </Box>
            </Tooltip>
          </Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "6px 8px 0px 8px",
              fontFamily: "Barlow !important",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "28px",
              color: "#3178B9",
            }}>
            Catalog Number
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "0px 8px 6px 8px",
              fontFamily: "Barlow !important",
              fontWeight: "500",
              fontSize: "28px",
              lineHeight: "32px",
              color: "#3178B9",
            }}>
            {configFromTemplateDataFromParent.pid}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "end", padding: "6px 8px 6px 8px" }}>
            <Button onClick={toggleConfigurationSummary}>{isViewSummaryVisible ? "Hide" : "View"} Summary</Button>
            <Button onClick={handleResetConfiguration} variant="outlined">
              RESET
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductRealTimeData;
