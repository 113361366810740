import { useLocation } from "react-router-dom";
import ProductApiService from "../../services/apis/ProductApiService";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import Response from "../../services/apis/Response";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { AddIcon } from "@rockwell-automation-inc/ra-meatball";
import SearchProduct from "../../interfaces/SearchProduct";
import { useAppDispatch } from "../../redux/hooks";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import toast from "../../util/toast";
import { PostBOMItem } from "../../interfaces/PostBOMItem";
import { BOMApiService, BOMItemApiService, selectUser, usePSAppSelector } from "platform-services";
import { BOM } from "../../interfaces/BOM";
import { selectSelBOM, setSelBOM } from "../../redux/slices/selectedBOMSlice";
import { PostBOM } from "../../interfaces/PostBOM";
import { BOMItemThumbprint } from "../../interfaces/BOMItemThumbprint";
import { getConfigBOMItemThumbPrint } from "../../util/ConfigBOMItemThumbprint";

interface ProductCardBomButtonProps {
  product: SearchProduct;
}

const ProductCardBomButton = (props: ProductCardBomButtonProps) => {
  const dispatch = useAppDispatch();
  const [qty, setQty] = useState(1);
  const location = useLocation();
  const user = usePSAppSelector(selectUser);
  const { product } = props;
  const selProject = useSelector(selectSelProject);
  const selBOM = useSelector(selectSelBOM);

  interface ProductResponse {
    data: DetailedProduct;
  }

  const handleQuickAdd = (event: any, catalogNumber: string) => {
    if (catalogNumber === "") {
      return;
    }
    event.stopPropagation();
    const sourceType = new URLSearchParams(location.search).get("type") || "";
    const savingToast = toast.loading("Saving...");
    const product = ProductApiService.GetDetailedProduct(catalogNumber, sourceType);
    product
      .then((response: ProductResponse) => {
        handleAddToBom(response.data, savingToast);
      })
      .catch(error => {
        // TODO
        console.log(error);
      });
  };

  const createNewBOM = async (savingToast: any) => {
    let bom: BOM;
    if (selProject?.guid !== undefined) {
      const postBom: PostBOM = {
        name: "DEFAULT BOM",
        projectId: selProject?.guid,
      };
      try {
        const result = await BOMApiService.postBOM(postBom);
        const response = new Response(result);
        if (response.isSuccessful()) {
          dispatch(setSelBOM(response.getData()));
          bom = response.getData();
          return bom;
        }
      } catch (e) {
        toast.dismiss(savingToast);
        toast.error(`Unable to add ${product.catalogNumber} to bom "${postBom.name}"`);
        console.log(e);
      }
    }
  };

  const addProductToSelectedBOM = async (product: DetailedProduct, savingToast: any, newBOM?: BOM | undefined) => {
    const bomItem: PostBOMItem = {
      name: product.catalogNumber,
      quantity: qty,
      sourceType: product.sourceType,
      children: [],
      configThumbprint: getConfigBOMItemThumbPrint(product),
    };
    try {
      if (newBOM) {
        const newBOMResult = await BOMItemApiService.postBOMItem(newBOM.id, bomItem);
        const response = new Response(newBOMResult);
        if (response.isSuccessful()) {
          toast.loadedSuccess(savingToast, `${product.catalogNumber} added to: ${selProject?.name}`);
        }
      }
      if (newBOM === undefined && selBOM) {
        const selBOMResult = await BOMItemApiService.postBOMItem(selBOM.id, bomItem);
        const response = new Response(selBOMResult);
        if (response.isSuccessful()) {
          toast.loadedSuccess(savingToast, `${product.catalogNumber} added to: ${selProject?.name}`);
        }
      }
    } catch (e) {
      toast.dismiss(savingToast);
      toast.error(`Unable to add ${product.catalogNumber} to "${selProject?.name}"`);
      console.log(e);
    }
    const addToBom = new CustomEvent("trackAddToBom", {
      detail: {
        action: "Add To Bom from Product Card",
        properties: {
          category: "WebApp",
          label: "Add To Bom from Product Card",
          product: product.catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(addToBom);
  };

  const handleAddToBom = async (product: DetailedProduct, savingToast: any) => {
    if (!product) return;
    if (selBOM === null) {
      createNewBOM(savingToast).then((newBOM: BOM | undefined) => {
        addProductToSelectedBOM(product, savingToast, newBOM);
      });
    } else {
      addProductToSelectedBOM(product, savingToast);
    }
  };
  if (user && (selBOM || selProject)) {
    return (
      <Button sx={{ mb: -3, width: "100%" }} variant="outlined" onClick={(event: any) => handleQuickAdd(event, product.catalogNumber)}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <AddIcon
            fontSize="small"
            sx={{
              marginRight: "10px",
            }}
          />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Barlow !important",
            fontWeight: "500",
            fontSize: "16px",
            color: "#2A79C4",
          }}>
          ADD TO PROJECT
        </Typography>
      </Button>
    );
  } else {
    return <></>;
  }
};

export default ProductCardBomButton;
