import {
  Modal,
  Backdrop,
  Box,
  IconButton,
  Button,
  Typography,
  Stepper,
  Step,
  Grid,
  ButtonBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  CardMedia,
  List,
  Tooltip,
  ListItem,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  styled,
  StepIconProps,
} from "@mui/material";
import { CloseIcon, TeaserCard, TeaserCardTitle, TeaserCardDescription } from "@rockwell-automation-inc/ra-meatball";
import { Link, useParams } from "react-router-dom";
import PATHS from "../../constants/Paths";
import ROUTES from "../../constants/Routes";
import { IndAppValues, selectedAttributes, valueMetadata, VoltPowValues } from "../../interfaces/ProductAssistant";
import productassistantApiService from "../../services/apis/ProductAssistantApiService";
import { useEffect, useState } from "react";
import { findNestedProduct } from "../../helpers/Utils";
import { useAppSelector } from "../../redux/hooks";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Check } from "@mui/icons-material";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import defaultImage from "../../assets/Image_Unavailable.svg";
import "../ProductAssistantTool/ProductAssistantTool.scss";
import { CatalogTemplate, selectCatalog, usePSAppSelector } from "platform-services";

interface ProductAssistantModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ProductAssitantTool = ({ isOpen, handleClose }: ProductAssistantModalProps) => {
  const { catalogGroupId } = useParams();
  const catalog = usePSAppSelector(selectCatalog);
  const steps = ["Industry", "Application", "Power Rating", "Recommendation"];
  const [activeStep, setActiveStep] = useState(0);
  const [industryList, setIndustryList] = useState<valueMetadata[] | null>(null);
  const [lineVoltage, setLineVoltage] = useState("");
  const [powerRating, setPowerRating] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [SelectedApp, setSeletedApp] = useState("");
  const [selectedApplicationList, setSelectedApplicationList] = useState<string[] | null>(null);
  const [productAssData, setProductAssData] = useState<selectedAttributes>();
  const [recommendedFamily, setRecommendedFamily] = useState<CatalogTemplate[] | undefined>([]);

  const IndustryAppData: string[] = productAssData?.rules?.[0]?.attributeValues || [];
  const LinePowerData: string[] = productAssData?.rules?.[1]?.attributeValues || [];
  const Filteredpower = LinePowerData.filter(item => item[0] === lineVoltage).map(item => item[1]);

  const uniquePower = Array.from(new Set(Filteredpower));

  const FilteredLineVoltage = LinePowerData.map(item => item[0]);
  const uniqueLineVoltageList = Array.from(new Set(FilteredLineVoltage));

  const AttributeValuesIndApp: IndAppValues[] = IndustryAppData.map(item => ({
    Industry: item[0],
    Application: item[1],
    TemplateId: item[2],
  }));
  const AttributeValuesVoltPow: VoltPowValues[] = LinePowerData.map(item => ({
    InputVoltage: item[0],
    PowerRating: item[1],
    TemplateId: item[2],
  }));
  const CustomConnector = styled(StepConnector)(({}) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: "linear-gradient(136deg,  rgba(0, 62, 140, 1) 40%, rgba(0, 174, 239, 1) 70%,  rgba(0, 174, 239, 1) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(136deg, rgba(0, 62, 140, 1) 100%, rgba(0, 62, 140, 0.9) 70%, rgba(0, 62, 140, 0.7) 60%, rgba(0, 174, 239, 1) 50%);",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      border: 0,
      width: "120%",
      backgroundColor: "#CCCCCC",
      marginLeft: "-20px",
    },
  }));
  const CustomStepLabel = styled(StepLabel)(() => ({
    ".Mui-completed": { color: "rgba(0, 70, 126, 5)!important" },
    ".Mui-active": { color: "rgba(0, 174, 239, 1)!important" },
  }));
  const CustomStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ ownerState }) => ({
    backgroundColor: ownerState.active ? "linear-gradient(136deg, rgba(33,150,243,1) 0%, rgba(3,169,244,1) 50%, rgba(0,188,212,1) 100%)" : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: ownerState.active
      ? "linear-gradient(136deg, rgba(0, 174, 239, 1) 0%, rgba(0, 174, 239, 1) 50%, rgba(0, 174, 239, 1) 100%)"
      : ownerState.completed
      ? "linear-gradient(136deg, rgba(0, 62,140, 1) 100%, rgba(0, 62,140, 0.8) 100%, rgba(0, 62,140, 0.6) 100%)"
      : "#ccc",
  }));
  function CustomStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <Check /> : <div />}
      </CustomStepIconRoot>
    );
  }
  const fetchUserData = async () => {
    try {
      const data = {
        family: "Drives",
        selectedAttributes: [],
      };
      const response = await productassistantApiService.ProductAssistant(data);
      setProductAssData(response.data);
      setIndustryList(response.data.rules[0].attributes[0].valueMetadata);
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleNext = () => {
    if (activeStep >= 0 && activeStep <= 2) setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    if (activeStep == 3) {
      setLineVoltage("");
      setPowerRating("");
    } else if (activeStep == 2) {
      setLineVoltage("");
      setPowerRating("");
      setSeletedApp("");
    } else if (activeStep == 1) {
      setSelectedIndustry("");
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleIndustryClick = (industry: string) => {
    const FilteredApplications = AttributeValuesIndApp.filter(item => item.Industry === industry).map(item => item.Application);
    const uniqueFilteredApp = Array.from(new Set(FilteredApplications));
    setSelectedIndustry(industry);
    setSelectedApplicationList(uniqueFilteredApp);
    handleNext();
  };

  const handleLineVoltageChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setLineVoltage(event.target.value);
  };

  const handlePowerRatingChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPowerRating(event.target.value);

    const filteredIndApp = AttributeValuesIndApp.filter(item => item.Industry === selectedIndustry && item.Application === SelectedApp);
    const filteredVoltPow = AttributeValuesVoltPow.filter(item => item.InputVoltage === lineVoltage && item.PowerRating === event.target.value);

    const matchingTemplateIds = filteredIndApp
      .filter(indAppItem => filteredVoltPow.some(voltPowItem => voltPowItem.TemplateId === indAppItem.TemplateId))
      .map(item => item.TemplateId);

    const uniqueTemplateIds = Array.from(new Set(matchingTemplateIds));
    const RecommendedFamilies = catalogGroupId
      ? findNestedProduct(catalog, catalogGroupId)?.childGroups.flatMap(child =>
          child.templates.filter(i => uniqueTemplateIds?.includes(i.templateId))
        )
      : [];
    setRecommendedFamily(RecommendedFamilies);
    handleNext();
  };

  const handleAppClick = (App: string) => {
    setSeletedApp(App);
    handleNext();
  };

  const Cost: React.FC<{ cost: number }> = ({ cost }) => {
    const inner = "#EDF7ED";
    const outer = "#468C4A";
    const disabled = "#BFEEA0";
    return (
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          backgroundColor: inner,
          border: `1px solid ${outer}`,
          width: "fit-content",
        }}>
        {[...Array(3)].map((_, index: number) => {
          return <AttachMoneyOutlinedIcon fontSize="large" sx={{ color: cost >= index + 1 ? outer : disabled }} />;
        })}
      </Box>
    );
  };
  const Features: React.FC<{ features: number }> = ({ features }) => {
    const inner = "#E5F6FD";
    const outer = "#0288D1";
    const disabled = "#BBDEFB";
    return (
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          backgroundColor: inner,
          border: `1px solid ${outer}`,
          width: "fit-content",
        }}>
        {[...Array(3)].map((_, index: number) => {
          return <FlashOnOutlinedIcon key={index} fontSize="large" sx={{ color: features >= index + 1 ? outer : disabled }} />;
        })}
      </Box>
    );
  };

  return (
    <Modal id="product-assitant-modal" open={isOpen} onClose={handleClose} disableScrollLock={true}>
      <Box
        sx={{
          width: "70vw",
          height: "85vh",
          bgcolor: "background.paper",
          padding: "20px 20px 40px 20px",
          gap: "5px",
          boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
        }}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="flex-start">
          <Button
            startIcon={<ArrowBackIcon sx={{ width: "18px", height: "18px" }} />}
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              position: "fixed",
              mr: 1,
              color: "#000",
              textTransform: "none",
            }}>
            <Typography id="back-btn-text">BACK</Typography>
          </Button>
          <Typography id="product-assistant-header" variant="h6" sx={{ paddingTop: "5%" }}>
            Product Assistant
          </Typography>
        </Box>

        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
          sx={{
            mt: 2,
            height: "10%",
            width: "96%",
          }}>
          {steps.map(label => (
            <Step key={label}>
              <CustomStepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  sx={{ width: "100%", fontSize: "14px !important", marginTop: -1 }}
                  dangerouslySetInnerHTML={{
                    __html:
                      label === "Industry"
                        ? `${label}<br />${selectedIndustry}`
                        : label === "Application"
                        ? `${label}<br />${SelectedApp}`
                        : label === "Power Rating"
                        ? `${label}<br />${lineVoltage}<br />${powerRating}`
                        : label,
                  }}
                />
              </CustomStepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid item xs={12}>
          <Typography id="product-assistant-select-text" sx={{ mt: 4, mb: 2 }}>
            {activeStep === 3 ? "Recommended products" : `Select the ${steps[activeStep]} this product will be used in`}
          </Typography>
          <Box sx={{ maxHeight: "45vh", overflow: "auto" }}>
            {activeStep === 0 && (
              <Grid container spacing={1}>
                {industryList
                  ?.sort((a, b) => a.value.localeCompare(b.value))
                  .map(industry => (
                    <Grid item xs={6} sm={4} md={2} p={2} key={industry.value}>
                      <ButtonBase onClick={() => handleIndustryClick(industry.value)}>
                        <Box
                          sx={{
                            height: "160px",
                            width: "150px",
                            padding: "15px",
                            border: "1px solid #ccc",
                            boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
                            borderRadius: "4px",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            "&:hover": {
                              boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
                              color: "rgba(205, 22, 63, 1)",
                            },
                          }}>
                          <CardMedia
                            component="img"
                            sx={{
                              width: "80%",
                              height: "60%",
                              objectFit: "contain",
                              margin: "auto",
                            }}
                            image={`${PATHS.IMG_BASE}${industry.image}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = defaultImage;
                            }}
                            alt={`${industry.value}_photo`}
                          />
                          <Typography id="industry-text" sx={{ display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
                            {industry.value}
                          </Typography>
                        </Box>
                      </ButtonBase>
                    </Grid>
                  ))}
              </Grid>
            )}

            {activeStep === 1 && (
              <Grid container spacing={1}>
                {selectedApplicationList
                  ?.sort((a, b) => {
                    if (a === "Other") return 1;
                    if (b === "Other") return -1;
                    return a.localeCompare(b);
                  })
                  .map(Application => (
                    <Grid item xs={6} sm={4} md={2} p={2} key={Application}>
                      <ButtonBase onClick={() => handleAppClick(Application)}>
                        <Box
                          sx={{
                            display: "flex",
                            height: "160px",
                            width: "150px",
                            padding: "15px",
                            border: "1px solid #ccc",
                            boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
                            borderRadius: "4px",
                            alignItems: "center",
                            cursor: "pointer",
                            "&:hover": {
                              boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
                              color: "rgba(205, 22, 63, 1)",
                            },
                          }}>
                          <Typography id="application-text">{Application}</Typography>
                        </Box>
                      </ButtonBase>
                    </Grid>
                  ))}
              </Grid>
            )}
            {activeStep === 2 && (
              <Box sx={{ display: "flex", gap: 2, padding: 1 }}>
                <FormControl sx={{ width: "35%" }}>
                  <InputLabel id="line-voltage-label">Line Voltage</InputLabel>
                  <Select labelId="line-voltage-label" label="Line Voltage" value={lineVoltage} onChange={handleLineVoltageChange}>
                    {uniqueLineVoltageList.map(Line => (
                      <MenuItem value={Line}>{Line}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "35%" }} disabled={lineVoltage === ""}>
                  <InputLabel id="power-rating-label">Power Rating</InputLabel>
                  <Select
                    labelId="power-rating-label"
                    label="Power Rating"
                    value={powerRating}
                    onChange={handlePowerRatingChange}
                    startAdornment={
                      lineVoltage === "" && (
                        <InputAdornment position="start">
                          <IconButton edge="start" disabled></IconButton>
                        </InputAdornment>
                      )
                    }>
                    {uniquePower.map(power => (
                      <MenuItem value={power}>{power}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {activeStep === 3 && (
              <Grid container spacing={1}>
                {recommendedFamily?.map((product: CatalogTemplate, index: number) => {
                  return (
                    <>
                      <Grid item key={index} sm={12} md={6} xl={4}>
                        <ButtonBase component={Link} to={`${ROUTES.SEARCH}?tids=${product.templateId}`}>
                          <TeaserCard
                            raVariant="vertical"
                            hover={false}
                            sx={{
                              p: 2,
                              height: "330px",
                              width: "270px",
                              gap: "30px",
                              justifyContent: "space-between",
                              border: "1px solid rgba(18, 133, 10, 1)",
                              mt: 2,
                              boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
                            }}>
                            <Box>
                              <Box display="flex" justifyContent="flex-end">
                                <Cost cost={product.cost} />
                                <Box sx={{ mr: 2 }} />
                                <Features features={product.features} />
                              </Box>
                              <CardMedia
                                component="img"
                                sx={{
                                  width: "90px",
                                  height: "100px",
                                  backgroundSize: "contain",
                                  objectFit: "contain",
                                  margin: "auto",
                                }}
                                image={`${PATHS.IMG_BASE}${product.photo}`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = defaultImage;
                                }}
                                alt={`${product.title}_photo`}
                              />
                              <TeaserCardTitle
                                sx={{
                                  fontSize: "20px",
                                  lineHeight: "28px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  mb: 2,
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}>
                                {product.title}
                              </TeaserCardTitle>
                              <TeaserCardDescription>
                                <List>
                                  {product.webFeatures.map(val => {
                                    return (
                                      <Tooltip title={val} placement="top">
                                        <ListItem>
                                          <Typography
                                            sx={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              width: "150px",
                                            }}>
                                            &bull; {val}
                                          </Typography>
                                        </ListItem>
                                      </Tooltip>
                                    );
                                  })}
                                </List>
                              </TeaserCardDescription>
                            </Box>
                          </TeaserCard>
                        </ButtonBase>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};
export default ProductAssitantTool;
