import { Box, CardMedia, Tooltip, Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductBomButtonMultiple from "../../productBomButton/ProductBomButtonMultiple";
import Carousel from "../../../components/carousel/Carousel";
import PATHS from "../../../constants/Paths";
import { LoadingIndicator, TeaserCard, TeaserCardSubtitle } from "@rockwell-automation-inc/ra-meatball";
import DisassembledProductCard from "./DisassembledProductCard";
import ProductApiService from "../../../services/apis/ProductApiService";
import ProductComponentResponse from "../../../interfaces/ProductComponentResponse";
import { DetailedProduct } from "../../../interfaces/DetailedProduct";
import { AxiosResponse } from "axios";
import { selectUser, usePSAppSelector } from "platform-services";
import { featureFlagItpacs769 } from "./featureFlag";

interface ProductCardProps {
  catalogNumber: string;
  handleSelectedProduct?: () => void;
}
const DisassembledProduct = (props: ProductCardProps) => {
  const { catalogNumber } = props;
  const user = usePSAppSelector(selectUser);
  const [productComponentsArray, setProductComponentsArray] = useState<ProductComponentResponse>();
  const [totalComponentsListPrice, setTotalComponentsListPrice] = useState<string>("");
  const [totalComponentsCount, setTotalComponentsCount] = useState<number>(0);
  const [productLoading, setProductLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<DetailedProduct | null>(null);

  useEffect(() => {
    if (catalogNumber.length > 0) {
      const searchapi = ProductApiService.GetProductComponents(catalogNumber);

      searchapi
        .then(response => {
          const searchComponents: ProductComponentResponse = response.data;
          setProductComponentsArray(searchComponents);
          setProductLoading(false);
        })
        .catch(error => {
          // Handle error
          setProductLoading(false);
        });

      const product = ProductApiService.GetDetailedProduct(catalogNumber, "Unknown");
      product
        .then((response: AxiosResponse<DetailedProduct>) => {
          setProduct(response.data);
        })
        .catch(error => {
          // TODO
          console.log(error);
        });
    }
  }, [catalogNumber]);

  useEffect(() => {
    var totalPrice = 0;
    let totalCount = 0;
    var currencySymbol = [""];
    if (productComponentsArray !== undefined && productComponentsArray.components.length > 0) {
      currencySymbol = productComponentsArray?.product.displayPrice.split("") || [""];
      productComponentsArray.components.forEach((component: { listPrice?: number; componentQty?: number }) => {
        if (component.listPrice !== undefined) {
          if (featureFlagItpacs769 && component.componentQty !== undefined) {
            totalPrice += component.listPrice * component.componentQty;
            totalCount += component.componentQty;
          } else {
            totalPrice += component.listPrice;
            totalCount += 1;
          }
        }
      });
      setTotalComponentsListPrice(`${currencySymbol[0]}${totalPrice.toFixed(2)}`);
      setTotalComponentsCount(totalCount);
    }
  }, [productComponentsArray]);

  return (
    <>
      {productLoading && <LoadingIndicator></LoadingIndicator>}
      {productComponentsArray?.components !== undefined && productComponentsArray?.components.length > 0 && !productLoading && (
        <Box
          sx={{
            display: "block",
            width: "100%",
          }}>
          <TeaserCard
            raVariant="vertical"
            hover={false}
            sx={{
              fontFamily: "inherit",
              //height: "384px",
              borderRadius: "4px",
              gap: "8px",
              border: "0px 0px 4px 0px",
              padding: "8px 24px 8px 24px",
            }}>
            {user &&
              (productComponentsArray.leadTimeDifferenceDisplay !== null || productComponentsArray.leadTimeDifferenceDisplay !== undefined) && (
                <>
                  <Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <TeaserCardSubtitle
                          sx={{
                            backgroundColor: productComponentsArray.totalCostDifferenceDisplay.includes("-") ? "#edf7ed" : "#FDEDED",
                            fontWeight: "500",
                            padding: "0px 8px 0px 8px",
                            border: productComponentsArray.totalCostDifferenceDisplay.includes("-") ? "1px solid #2e7d32" : "1px solid #D32F2F",
                            borderRadius: "30px",
                            fontsize: "14px",
                            lineHeight: "22px",
                            color: productComponentsArray.totalCostDifferenceDisplay.includes("-") ? "#2e7d32" : "#D32F2F",
                            marginTop: "5px",
                            marginLeft: "10px",
                            fontFamily: "Barlow !important",
                          }}>
                          {productComponentsArray.totalCostDifferenceDisplay.split("-")}
                        </TeaserCardSubtitle>
                        <TeaserCardSubtitle
                          sx={{
                            backgroundColor: "#E5F6FD",
                            fontWeight: "500",
                            padding: "0px 8px 0px 8px",
                            border: "1px solid #0288d1",
                            borderRadius: "30px",
                            fontsize: "14px",
                            lineHeight: "22px",
                            color: "#0288d1",
                            marginTop: "5px",
                            marginLeft: "10px",
                            fontFamily: "Barlow !important",
                          }}>
                          {productComponentsArray.leadTimeDifferenceDisplay.includes("-") ? (
                            <>{productComponentsArray.leadTimeDifferenceDisplay.split("-")}</>
                          ) : (
                            <>{productComponentsArray?.leadTimeDifferenceDisplay}</>
                          )}
                        </TeaserCardSubtitle>
                        {!productComponentsArray?.product.stockStatusDisplay.split(":") && (
                          <TeaserCardSubtitle
                            sx={{
                              backgroundColor: "#edf7ed",
                              fontWeight: "500",
                              padding: "0px 8px 0px 8px",
                              border: "1px solid #2e7d32",
                              borderRadius: "30px",
                              fontsize: "14px",
                              lineHeight: "22px",
                              color: "#2e7d32",
                              marginTop: "5px",
                              marginLeft: "10px",
                              fontFamily: "Barlow !important",
                            }}>
                            in stock
                          </TeaserCardSubtitle>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            <Grid container justifyContent="space-between" direction="row" sx={{ mt: 4, mb: 1, gap: 1 }}>
              {productComponentsArray?.product && (
                <>
                  <Grid sx={{ display: "flex" }}>
                    <Grid md={2} sx={{ marginLeft: "10px", marginRight: "10px" }}>
                      <CardMedia
                        sx={{
                          width: "120px",
                          minWidth: "120px",
                          height: "120px",
                          minHeight: "120px",
                          backgroundSize: "contain",
                          objectFit: "contain",
                        }}
                        component="img"
                        image={`${PATHS.IMG_BASE}${productComponentsArray?.product.photo}`}
                        alt="component_img"
                      />
                    </Grid>
                    <Grid md={10}>
                      <Typography
                        sx={{
                          fontFamile: "Barlow !important",
                          mb: 2,
                          fontSize: "16px",
                        }}>
                        {productComponentsArray?.product.catalogNumber}
                      </Typography>
                      <Typography variant="h5" component="h1" fontWeight="bold" sx={{ mb: 2 }}>
                        {productComponentsArray?.product.templateDescription}
                      </Typography>
                      {user ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              paddingBottom: "10px",
                            }}>
                            <Typography
                              sx={{
                                textDecoration: "line-through",
                                fontFamily: "Barlow !important",
                                fontWeight: "500",
                                fontSize: "18px",
                                lineHeight: "24px",
                              }}>
                              {productComponentsArray?.product.displayPrice}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Barlow !important",
                                fontWeight: "500",
                                fontSize: "18px",
                                lineHeight: "24px",
                                marginLeft: "10px",
                              }}>
                              {totalComponentsListPrice}
                            </Typography>
                            <Tooltip
                              sx={{ marginLeft: "10px" }}
                              placement="bottom"
                              arrow
                              title="Disassembled products are usually more expensive than a factory assembled product. The crossed out price is what you would pay for the assembled product. Shipping costs included in the price.">
                              <InfoOutlinedIcon></InfoOutlinedIcon>
                            </Tooltip>
                          </Box>
                          <Typography
                            sx={{
                              fontFamily: "Barlow !important",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "18px",
                              paddingBottom: "10px",
                            }}>
                            {productComponentsArray?.product.stockStatusDisplay.length ? (
                              <>{productComponentsArray?.product.stockStatusDisplay}</>
                            ) : (
                              <>Contact Sales For Lead Time</>
                            )}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              paddingBottom: "10px",
                            }}>
                            <Typography sx={{ color: "#003E7E" }}>Log in to see the price</Typography>
                            <Tooltip
                              sx={{ marginLeft: "10px" }}
                              placement="bottom"
                              arrow
                              title="Disassembled products are usually more expensive than a factory assembled product. The crossed out price is what you would pay for the assembled product. Shipping costs included in the price.">
                              <InfoOutlinedIcon></InfoOutlinedIcon>
                            </Tooltip>
                          </Box>
                          <Typography sx={{ paddingBottom: "10px", color: "#003E7E" }}>Log in to see lead time</Typography>
                        </>
                      )}
                      <Typography>{productComponentsArray?.product.description}</Typography>
                      {productComponentsArray !== undefined && productComponentsArray.components.length && (
                        <Typography sx={{ color: "#2A79C4", marginTop: "10px" }}>
                          {productComponentsArray.components.length === 1 ? (
                            <>
                              1{` `}
                              {featureFlagItpacs769 ? "Component" : "Piece"}
                              {` `}
                              makes up this product
                            </>
                          ) : (
                            <>
                              {totalComponentsCount}
                              {` `}
                              {featureFlagItpacs769 ? "Components" : "Pieces"}
                              {` `}
                              make up this product
                            </>
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid>
              <Grid item xs={12}>
                {productComponentsArray !== undefined && productComponentsArray?.components.length > 0 && (
                  <Carousel CardComponent={DisassembledProductCard} products={productComponentsArray.components}></Carousel>
                )}
              </Grid>
              <Grid item xs={12}>
                <ProductBomButtonMultiple componentArray={productComponentsArray?.components}></ProductBomButtonMultiple>
              </Grid>
            </Grid>
          </TeaserCard>
        </Box>
      )}
      {!productLoading && (
        <Box>
          {productComponentsArray !== undefined && productComponentsArray?.message !== undefined ? (
            <Typography>{productComponentsArray.message}</Typography>
          ) : (
            <Typography>An error occured. Contact the sales team if issues continues</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default DisassembledProduct;
